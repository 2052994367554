const questionBank = {
  fr: {
    '0': {
      question: "Dans quelle mesure êtes-vous motivé à participer à cette formation ?",
      shortName: "Motivation",
      questionType: "radio-ordered",
      options: ["Pas du tout motivé", "Pas motivé", "Plutôt pas motivé", "Ni motivé ni démotivé", "Plutôt motivé", "Motivé", "Tout à fait motivé"]
    },
    '1': {
      question: "Dans quelle mesure considérez-vous la technologie bénéfique pour votre travail ?",
      shortName: "Bénéfices de la Technologie",
      questionType: "radio-ordered",
      options: ["Pas du tout bénéfique", "Pas bénéfique", "Plutôt pas bénéfique", "Ni bénéfique ni nuisible", "Plutôt bénéfique", "Bénéfique", "Tout à fait bénéfique"]
    },
    '2': {
      question: "Dans quelle mesure les opinions des autres influencent-elles votre décision de participer à cette formation ?",
      shortName: "Influence des Pairs",
      questionType: "radio-ordered",
      options: ["Pas du tout influencé", "Pas influencé", "Plutôt pas influencé", "Ni influencé ni non influencé", "Plutôt influencé", "Influencé", "Tout à fait influencé"]
    },
    '3': {
      question: "Dans quelle mesure votre inscription à cette formation était-elle un choix personnel plutôt qu'une obligation ?",
      shortName: "Choix Personnel",
      questionType: "radio-ordered",
      options: ["Complètement une obligation", "Principalement une obligation", "Plutôt une obligation", "Ni un choix personnel ni une obligation", "Plutôt un choix personnel", "Principalement un choix personnel", "Complètement un choix personnel"]
    },
    '4': {
      question: "Dans quelle mesure vous sentez-vous capable d'utiliser les technologies de l'information pour accomplir vos tâches ?",
      shortName: "Confiance en Technologie",
      questionType: "radio-ordered",
      options: ["Pas du tout capable", "Pas capable", "Plutôt pas capable", "Ni capable ni incapable", "Plutôt capable", "Capable", "Tout à fait capable"]
    },
    '5': {
      question: "Comment évalueriez-vous la capacité de l'instructeur à engager les participants ?",
      shortName: "Engagement",
      questionType: "radio-ordered",
      options: ["Pas du tout engageant", "Pas engageant", "Plutôt pas engageant", "Ni engageant ni non engageant", "Plutôt engageant", "Engageant", "Tout à fait engageant"]
    },
    '6': {
      question: "Comment évalueriez-vous la capacité de l'instructeur à gérer le temps ?",
      shortName: "Gestion du Temps",
      questionType: "radio-ordered",
      options: ["Très mauvaise gestion du temps", "Mauvaise gestion du temps", "Plutôt mauvaise gestion du temps", "Gestion du temps moyenne", "Bonne gestion du temps", "Très bonne gestion du temps", "Excellente gestion du temps"]
    },
    '7': {
      question: "Comment évalueriez-vous la pertinence des méthodes d'enseignement de l'instructeur ?",
      shortName: "Méthodes d'Enseignement",
      questionType: "radio-ordered",
      options: ["Pas du tout pertinent", "Pas pertinent", "Plutôt pas pertinent", "Neutre", "Plutôt pertinent", "Pertinent", "Tout à fait pertinent"]
    },
    '8': {
      question: "Comment évalueriez-vous la pertinence de l'environnement d'apprentissage ?",
      shortName: "Environnement",
      questionType: "radio-ordered",
      options: ["Pas du tout pertinent", "Pas pertinent", "Plutôt pas pertinent", "Neutre", "Plutôt pertinent", "Pertinent", "Tout à fait pertinent"]
    },
    '9': {
      question: "Comment évalueriez-vous la pertinence des outils pédagogiques utilisés ?",
      shortName: "Pertinence des Outils",
      questionType: "radio-ordered",
      options: ["Pas du tout pertinent", "Pas pertinent", "Plutôt pas pertinent", "Neutre", "Plutôt pertinent", "Pertinent", "Tout à fait pertinent"]
    },
    '10': {
      question: "Comment évalueriez-vous la capacité de l'instructeur à adapter ses méthodes d'enseignement au public ?",
      shortName: "Pédagogie",
      questionType: "radio-ordered",
      options: ["Pas du tout pertinent", "Pas pertinent", "Plutôt pas pertinent", "Neutre", "Plutôt pertinent", "Pertinent", "Tout à fait pertinent"]
    },
    '11': {
      question: "À quel point avez-vous apprécié la session de formation ?",
      shortName: "Satisfaction",
      questionType: "radio-ordered",
      options: ["Pas du tout apprécié", "Pas apprécié", "Plutôt pas apprécié", "Ni apprécié ni pas apprécié", "Plutôt apprécié", "Apprécié", "Tout à fait apprécié"]
    },
    '12': {
      question: "À quel point avez-vous trouvé la session de formation utile ?",
      shortName: "Utilité Perçue",
      questionType: "radio-ordered",
      options: ["Pas du tout utile", "Pas utile", "Plutôt pas utile", "Ni utile ni inutile", "Plutôt utile", "Utile", "Tout à fait utile"]
    },
    '13': {
      question: "À quel point trouvez-vous facile d'intégrer ce que vous avez vu dans une activité en classe ?",
      shortName: "Facilité d'Utilisation",
      questionType: "radio-ordered",
      options: ["Pas du tout facile", "Pas facile", "Plutôt pas facile", "Ni facile ni difficile", "Plutôt facile", "Facile", "Tout à fait facile"]
    },
    '14': {
      question: "À quel point vous sentez-vous confiant à l'idée d'intégrer ce que vous avez vu dans une activité en classe ?",
      shortName: "Auto-Efficacité",
      questionType: "radio-ordered",
      options: ["Pas du tout confiant", "Pas confiant", "Plutôt pas confiant", "Ni confiant ni pas confiant", "Plutôt confiant", "Confiant", "Tout à fait confiant"]
    },
    '15': {
      question: "Dans quelle mesure avez-vous l'intention d'utiliser ce que vous avez vu pour mener une activité en classe ?",
      shortName: "Intention Comportementale",
      questionType: "radio-ordered",
      options: ["Pas du tout l'intention d'intégrer", "Pas l'intention d'intégrer", "Plutôt pas l'intention d'intégrer", "Ni l'intention ni pas l'intention", "Plutôt  l'intention d'intégrer", "Intention d'intégrer", "Tout à fait l'intention d'intégrer"]
    },
    '16': {
      question: "À quel niveau estimez-vous votre besoin en matériel nécessaire pour intégrer efficacement les ressources de formation dans votre pratique ?",
      shortName: "Ressources Matérielles",
      questionType: "radio-ordered",
      options: ["Très élevé", "Élevé", "Assez élevé", "Moyen", "Assez faible", "Faible", "Très faible"]
    },
    '17': {
      question: "À quel niveau estimez-vous votre besoin de temps disponible pour intégrer les ressources de formation dans votre pratique ?",
      shortName: "Ressources en Temps",
      questionType: "radio-ordered",
      options: ["Très élevé", "Élevé", "Assez élevé", "Moyen", "Assez faible", "Faible", "Très faible"]
    },
    '18': {
      question: "À quel niveau estimez-vous votre besoin de soutien de la part de votre hiérarchie pour intégrer les ressources de formation dans votre pratique ?",
      shortName: "Soutien Hiérarchique",
      questionType: "radio-ordered",
      options: ["Très élevé", "Élevé", "Assez élevé", "Moyen", "Assez faible", "Faible", "Très faible"]
    },
    '19': {
      question: "À quel niveau estimez-vous votre besoin de soutien de la part de vos collègues pour intégrer les ressources de formation dans votre pratique ?",
      shortName: "Soutien des Pairs",
      questionType: "radio-ordered",
      options: ["Très élevé", "Élevé", "Assez élevé", "Moyen", "Assez faible", "Faible", "Très faible"]
    },
    '20': {
      question: "À quel niveau estimez-vous votre besoin de soutien technique pour intégrer les ressources de formation dans votre pratique ?",
      shortName: "Soutien Technique",
      questionType: "radio-ordered",
      options: ["Très élevé", "Élevé", "Assez élevé", "Moyen", "Assez faible", "Faible", "Très faible"]
    },
    '21': {
      question: "À quel niveau estimez-vous votre besoin de mentorat (par exemple, avec un collègue plus expérimenté) pour intégrer les ressources de formation dans votre pratique ?",
      shortName: "Mentorat",
      questionType: "radio-ordered",
      options: ["Très élevé", "Élevé", "Assez élevé", "Moyen", "Assez faible", "Faible", "Très faible"]
    },
    '22': {
      question: "Dans quelle mesure le matériel introduit est-il compatible avec vos pratiques d'enseignement antérieures ?",
      shortName: "Compatibilité",
      questionType: "radio-ordered",
      options: ["Pas du tout compatible", "Pas compatible", "Plutôt pas compatible", "Ni compatible ni incompatible", "Plutôt compatible", "Compatible", "Tout à fait compatible"]
    },
    '23': {
      question: "Comment trouvez-vous la progression de vos élèves avec les nouveaux matériaux ?",
      shortName: "Progression des Élèves",
      questionType: "radio-ordered",
      options: ["Significativement diminuée", "Légèrement diminuée", "Restée la même", "Légèrement augmentée", "Modérément augmentée", "Significativement augmentée"]
    },
    '24': {
      question: "Comment évalueriez-vous votre sentiment d'auto-efficacité à utiliser les nouvelles ressources ?",
      shortName: "Auto-Efficacité",
      questionType: "radio-ordered",
      options: ["Pas du tout confiant", "Pas confiant", "Plutôt pas confiant", "Ni confiant ni pas confiant", "Plutôt confiant", "Confiant", "Tout à fait confiant"]
    },
    '25': {
      question: "À quel point trouvez-vous mentalement exigeante la mise en œuvre des nouvelles ressources ?",
      shortName: "Charge Cognitive",
      questionType: "radio-ordered",
      options: ["Extrêmement exigeante", "Très exigeante", "Assez exigeante", "Modérément exigeante", "Légèrement exigeante", "Pas du tout exigeante"]
    },
    '26': {
      question: "Dans quelle mesure les nouvelles ressources augmentent-elles votre charge de travail ?",
      shortName: "Impact sur la Charge de Travail",
      questionType: "radio-ordered",
      options: ["Massivement augmenté", "Significativement augmenté", "Assez augmenté", "Neutre", "Modérément augmenté", "Légèrement augmenté", "Pas du tout augmenté"]
    },
    '27': {
      question: "Combien de temps vous a-t-il fallu pour mettre en œuvre les nouveaux matériaux après la formation ?",
      shortName: "Temps de Mise en Œuvre",
      questionType: "radio-ordered",
      options: ["Pas encore mis en œuvre", "Plus de 3 mois", "Dans les 3 mois", "Dans le mois", "Dans la semaine", "Immédiatement"]
    },
    '28': {
      question: "À quel groupe d'âge appartenez-vous ?",
      shortName: "Âge",
      questionType: "radio-unordered",
      options: ["Moins de 10 ans", "10-15 ans", "16-20 ans", "21-25 ans", "26-30 ans", "31 ans et plus"]
    },
    '29': {
      question: "Quel est votre principal domaine de spécialisation ou d'intérêt ?",
      shortName: "Spécialisation",
      questionType: "radio-unordered",
      options: ["Sciences", "Arts", "Technologie", "Mathématiques", "Humanités", "Autre"]
    },
    '30': {
      question: "À quel niveau d'éducation êtes-vous actuellement ?",
      shortName: "Niveau d'Éducation",
      questionType: "radio-unordered",
      options: ["Primaire", "Secondaire", "Licence (ou équivalent)", "Master/Doctorat (ou équivalent)"]
    },
    '31': {
      question: "À quelle fréquence utilisez-vous des outils numériques pour apprendre ou vous divertir ?",
      shortName: "Utilisation des Outils Numériques",
      questionType: "radio-unordered",
      options: ["Jamais", "Rarement", "Parfois", "Souvent", "Toujours"]
    },
    '32': {
      question: "Pensez-vous qu'il existe certains genres plus adaptés à l'informatique ?",
      shortName: "Stéréotypes de Genre",
      questionType: "radio-unordered",
      options: ["Oui, les hommes", "Oui, les femmes", "Non, tous les genres sont également adaptés", "Je n'ai pas d'opinion sur la question"]
    }
  },

  en: {
    '0': {
      question: "To what extent are you motivated to participate in this training?",
      shortName: "Motivation",
      questionType: "radio-ordered",
      options: ["Not at all motivated", "Slightly motivated", "Somewhat unmotivated", "Neither motivated nor unmotivated", "Somewhat motivated", "Very motivated", "Extremely motivated"]
    },
    '1': {
      question: "To what extent do you consider technology beneficial to your work?",
      shortName: "Benefits of Technology",
      questionType: "radio-ordered",
      options: ["Not at all beneficial", "Slightly beneficial", "Somewhat not beneficial", "Neither beneficial nor harmful", "Somewhat beneficial", "Very beneficial", "Extremely beneficial"]
    },
    '2': {
      question: "To what extent do others' opinions influence your decision to participate in this training?",
      shortName: "Peer Influence",
      questionType: "radio-ordered",
      options: ["Not influenced at all", "Slightly influenced", "Somewhat not influenced", "Neither influenced nor not influenced", "Somewhat influenced", "Very influenced", "Extremely influenced"]
    },
    '3': {
      question: "To what extent was your enrollment in this training a personal choice rather than an obligation?",
      shortName: "Personal Choice",
      questionType: "radio-ordered",
      options: ["Completely an obligation", "Mostly an obligation", "Somewhat an obligation", "Neither a personal choice nor an obligation", "Somewhat a personal choice", "Mostly a personal choice", "Completely a personal choice"]
    },
    '4': {
      question: "To what extent do you feel capable of using IT technologies to accomplish your tasks?",
      shortName: "Technology Confidence",
      questionType: "radio-ordered",
      options: ["Not capable at all", "Slightly capable", "Somewhat not capable", "Neither capable nor incapable", "Somewhat capable", "Very capable", "Extremely capable"]
    },


    '5': {
      question: "How would you rate the trainer's ability to engage participants?",
      shortName: "Engagement",
      questionType: "radio-ordered",
      options: ["Not engaging at all", "Rarely engaging", "Somewhat not engaging", "Neutral", "Quite engaging", "Generally engaging", "Always engaging"]
    },
    '6': {
      question: "How would you rate the trainer's ability to manage time?",
      shortName: "Time Management",
      questionType: "radio-ordered",
      options: ["Very poor time management", "Poor time management", "Somewhat poor time management", "Average time management", "Good time management", "Very good time management", "Excellent time management"]
    },
    '7': {
      question: "How would you rate the relevance of the trainer's teaching methods?",
      shortName: "Teaching Methods",
      questionType: "radio-ordered",
      options: ["Not relevant at all", "Slightly relevant", "Somewhat not relevant", "Neutral", "Quite relevant", "Very relevant", "Extremely relevant"]
    },
    '8': {
      question: "How would you rate the relevance of the learning environment?",
      shortName: "Environment",
      questionType: "radio-ordered",
      options: ["Not relevant at all", "Slightly relevant", "Somewhat not relevant", "Neutral", "Quite relevant", "Very relevant", "Extremely relevant"]
    },
    '9': {
      question: "How would you rate the relevance of the teaching tools used?",
      shortName: "Tool Suitability",
      questionType: "radio-ordered",
      options: ["Not relevant at all", "Slightly relevant", "Somewhat not relevant", "Neutral", "Quite relevant", "Very relevant", "Extremely relevant"]
    },
    '10': {
      question: "How would you rate the trainer's ability to adapt their teaching methods to suit the audience?",
      shortName: "Pedagogy",
      questionType: "radio-ordered",
      options: ["Not relevant at all", "Slightly relevant", "Somewhat not relevant", "Neutral", "Quite relevant", "Very relevant", "Extremely relevant"]
    },
    '11': {
      question: "How much did you enjoy the training session?",
      shortName: "Perceived Enjoyment",
      questionType: "radio-ordered",
      options: ["Did not enjoy at all", "Slightly enjoyed", "Quite enjoyed", "Neutral", "Very enjoyed", "Extremely enjoyed"]
    },
    '12': {
      question: "How useful did you find the training session?",
      shortName: "Perceived Usefulness",
      questionType: "radio-ordered",
      options: ["Not useful at all", "Slightly useful", "Moderately useful", "Neutral", "Very useful", "Extremely useful"]
    },
    '13': {
      question: "How easy do you find integrating what you've seen into a classroom activity?",
      shortName: "Perceived Ease of Use",
      questionType: "radio-ordered",
      options: ["Not easy at all", "A bit difficult", "Moderately easy", "Neutral", "Quite easy", "Very easy", "Extremely easy"]
    },
    '14': {
      question: "How confident do you feel about integrating what you've seen into a classroom activity?",
      shortName: "Self-Efficacy",
      questionType: "radio-ordered",
      options: ["Not confident at all", "Slightly confident", "Moderately confident", "Neutral", "Very confident", "Extremely confident"]
    },
    '15': {
      question: "To what extent do you intend to use what you've seen to conduct a classroom activity?",
      shortName: "Behavioral Intention",
      questionType: "radio-ordered",
      options: ["Do not intend to integrate at all", "Slight intention to integrate", "Moderate intention to integrate", "Neutral", "Strong intention to integrate", "Very strong intention to integrate", "Absolutely intend to integrate"]
    },


    '16': {
      question: "At what level do you estimate your need for necessary equipment to effectively integrate training resources into your practice?",
      shortName: "Material Resources",
      questionType: "radio-ordered",
      options: ["Very high", "High", "Quite high", "Medium", "Quite low", "Low", "Very low"]
    },
    '17': {
      question: "At what level do you estimate your need for available time to integrate training resources into your practice?",
      shortName: "Time Resources",
      questionType: "radio-ordered",
      options: ["Very high", "High", "Quite high", "Medium", "Quite low", "Low", "Very low"]
    },
    '18': {
      question: "At what level do you estimate your need for support from your hierarchy to integrate training resources into your practice?",
      shortName: "Hierarchical Support",
      questionType: "radio-ordered",
      options: ["Very high", "High", "Quite high", "Medium", "Quite low", "Low", "Very low"]
    },
    '19': {
      question: "At what level do you estimate your need for support from your colleagues to integrate training resources into your practice?",
      shortName: "Peer Support",
      questionType: "radio-ordered",
      options: ["Very high", "High", "Quite high", "Medium", "Quite low", "Low", "Very low"]
    },
    '20': {
      question: "At what level do you estimate your need for technical support to integrate training resources into your practice?",
      shortName: "Technical Support",
      questionType: "radio-ordered",
      options: ["Very high", "High", "Quite high", "Medium", "Quite low", "Low", "Very low"]
    },
    '21': {
      question: "At what level do you estimate your need for mentoring (for example, with a more experienced colleague) to integrate training resources into your practice?",
      shortName: "Mentorship",
      questionType: "radio-ordered",
      options: ["Very high", "High", "Quite high", "Medium", "Quite low", "Low", "Very low"]
    },


    '22': {
      question: "How compatible is the introduced material with your prior teaching practices?",
      shortName: "Compatibility",
      questionType: "radio-ordered",
      options: ["Not compatible at all", "Slightly compatible", "Quite compatible", "Neutral", "Moderately compatible", "Very compatible", "Extremely compatible"]
    },
    '23': {
      question: "How do you find your students progress with the new materials?",
      shortName: "Student Progress",
      questionType: "radio-ordered",
      options: ["Decreased significantly", "Decreased slightly", "Remained the same", "Increased slightly", "Moderately increased", "Increased significantly"]
    },
    '24': {
      question: "How would you rate your sense of self-efficacy in using the new resources?",
      shortName: "Self-Efficacy",
      questionType: "radio-ordered",
      options: ["Not confident at all", "Slightly confident", "Quite confident", "Neutral", "Moderately confident", "Very confident", "Extremely confident"]
    },
    '25': {
      question: "How mentally demanding do you find the implementation of the new resources?",
      shortName: "Cognitive Load",
      questionType: "radio-ordered",
      options: ["Extremely demanding", "Very demanding", "Quite demanding", "Moderately demanding", "Slightly demanding", "Not at all demanding"]
    },
    '26': {
      question: "To what extent do the new resources increase your workload?",
      shortName: "Impact on Workload",
      questionType: "radio-ordered",
      options: ["Massively increased", "Significantly increased", "Quite increased", "Neutral", "Moderately increased", "Slightly increased", "Did not increase at all"]
    },
    '27': {
      question: "How long did it take you to implement the new materials after the training?",
      shortName: "Implementation Time",
      questionType: "radio-ordered",
      options: ["Not yet implemented", "More than 3 months", "Within 3 months", "Within a month", "Within a week", "Immediately"]
    },


    '28': {
      question: "Which age group do you belong to?",
      shortName: "Age",
      questionType: "radio-unordered",
      options: ["Under 10 years", "10-15 years", "16-20 years", "21-25 years", "26-30 years", "31 years and above"]
    },
    '29': {
      question: "What is your main area of specialization or interest?",
      shortName: "Specialization",
      questionType: "radio-unordered",
      options: ["Sciences", "Arts", "Technology", "Mathematics", "Humanities", "Other"]
    },
    '30': {
      question: "Which education level are you currently in?",
      shortName: "Education Level",
      questionType: "radio-unordered",
      options: ["Primary", "Secondary", "Bachelor's (or equivalent)", "Master's/Doctorate (or equivalent)"]
    },
    '31': {
      question: "How often do you use digital tools for learning or leisure?",
      shortName: "Use of Digital Tools",
      questionType: "radio-unordered",
      options: ["Never", "Rarely", "Sometimes", "Often", "Always"]
    },
    '32': {
      question: "Do you believe there are certain genders more suited to computer science?",
      shortName: "Gender Stereotypes",
      questionType: "radio-unordered",
      options: ["Yes, men", "Yes, women", "No, all genders are equally suited", "I have no opinion on the matter"]
    }
  },
  de: {
    '0': {
      question: "Inwieweit sind Sie motiviert, an dieser Schulung teilzunehmen?",
      shortName: "Motivation",
      questionType: "radio-ordered",
      options: ["Überhaupt nicht motiviert", "Wenig motiviert", "Eher unmotiviert", "Weder motiviert noch unmotiviert", "Eher motiviert", "Sehr motiviert", "Äußerst motiviert"]
    },
    '1': {
      question: "Inwieweit halten Sie Technologie für vorteilhaft für Ihre Arbeit?",
      shortName: "Vorteile der Technologie",
      questionType: "radio-ordered",
      options: ["Überhaupt nicht vorteilhaft", "Wenig vorteilhaft", "Eher nicht vorteilhaft", "Weder vorteilhaft noch nachteilig", "Eher vorteilhaft", "Sehr vorteilhaft", "Äußerst vorteilhaft"]
    },
    '2': {
      question: "Inwieweit beeinflussen die Meinungen anderer Ihre Entscheidung, an dieser Schulung teilzunehmen?",
      shortName: "Einfluss von Kollegen",
      questionType: "radio-ordered",
      options: ["Überhaupt nicht beeinflusst", "Wenig beeinflusst", "Eher nicht beeinflusst", "Weder beeinflusst noch nicht beeinflusst", "Eher beeinflusst", "Sehr beeinflusst", "Äußerst beeinflusst"]
    },
    '3': {
      question: "Inwieweit war Ihre Anmeldung zu dieser Schulung eine persönliche Wahl und keine Verpflichtung?",
      shortName: "Persönliche Wahl",
      questionType: "radio-ordered",
      options: ["Komplett eine Verpflichtung", "Hauptsächlich eine Verpflichtung", "Eher eine Verpflichtung", "Weder eine persönliche Wahl noch eine Verpflichtung", "Eher eine persönliche Wahl", "Hauptsächlich eine persönliche Wahl", "Komplett eine persönliche Wahl"]
    },
    '4': {
      question: "Inwieweit fühlen Sie sich in der Lage, IT-Technologien zu nutzen, um Ihre Aufgaben zu erledigen?",
      shortName: "Technologievertrauen",
      questionType: "radio-ordered",
      options: ["Überhaupt nicht in der Lage", "Wenig in der Lage", "Eher nicht in der Lage", "Weder in der Lage noch unfähig", "Eher in der Lage", "Sehr in der Lage", "Äußerst in der Lage"]
    },
    '5': {
      question: "Wie bewerten Sie die Fähigkeit des Trainers, die Teilnehmer zu engagieren?",
      shortName: "Engagement",
      questionType: "radio-ordered",
      options: ["Überhaupt nicht engagierend", "Selten engagierend", "Eher nicht engagierend", "Neutral", "Ziemlich engagierend", "Allgemein engagierend", "Immer engagierend"]
    },
    '6': {
      question: "Wie bewerten Sie die Fähigkeit des Trainers, die Zeit zu managen?",
      shortName: "Zeitmanagement",
      questionType: "radio-ordered",
      options: ["Sehr schlechtes Zeitmanagement", "Schlechtes Zeitmanagement", "Eher schlechtes Zeitmanagement", "Durchschnittliches Zeitmanagement", "Gutes Zeitmanagement", "Sehr gutes Zeitmanagement", "Exzellentes Zeitmanagement"]
    },
    '7': {
      question: "Wie bewerten Sie die Relevanz der Lehrmethoden des Trainers?",
      shortName: "Lehrmethoden",
      questionType: "radio-ordered",
      options: ["Überhaupt nicht relevant", "Wenig relevant", "Eher nicht relevant", "Neutral", "Ziemlich relevant", "Sehr relevant", "Äußerst relevant"]
    },
    '8': {
      question: "Wie bewerten Sie die Relevanz der Lernumgebung?",
      shortName: "Umgebung",
      questionType: "radio-ordered",
      options: ["Überhaupt nicht relevant", "Wenig relevant", "Eher nicht relevant", "Neutral", "Ziemlich relevant", "Sehr relevant", "Äußerst relevant"]
    },
    '9': {
      question: "Wie bewerten Sie die Relevanz der verwendeten Lehrmittel?",
      shortName: "Werkzeugtauglichkeit",
      questionType: "radio-ordered",
      options: ["Überhaupt nicht relevant", "Wenig relevant", "Eher nicht relevant", "Neutral", "Ziemlich relevant", "Sehr relevant", "Äußerst relevant"]
    },
    '10': {
      question: "Wie bewerten Sie die Fähigkeit des Trainers, seine Lehrmethoden an das Publikum anzupassen?",
      shortName: "Pädagogik",
      questionType: "radio-ordered",
      options: ["Überhaupt nicht relevant", "Wenig relevant", "Eher nicht relevant", "Neutral", "Ziemlich relevant", "Sehr relevant", "Äußerst relevant"]
    },
    '11': {
      question: "Wie sehr haben Sie die Schulungssitzung genossen?",
      shortName: "Gefallen",
      questionType: "radio-ordered",
      options: ["Überhaupt nicht genossen", "Wenig genossen", "Ziemlich genossen", "Neutral", "Sehr genossen", "Äußerst genossen"]
    },
    '12': {
      question: "Wie nützlich fanden Sie die Schulungssitzung?",
      shortName: "Nützlichkeit",
      questionType: "radio-ordered",
      options: ["Überhaupt nicht nützlich", "Wenig nützlich", "Mäßig nützlich", "Neutral", "Sehr nützlich", "Äußerst nützlich"]
    },
    '13': {
      question: "Wie einfach finden Sie es, das Gesehene in eine Klassenaktivität zu integrieren?",
      shortName: "Benutzerfreundlichkeit",
      questionType: "radio-ordered",
      options: ["Überhaupt nicht einfach", "Etwas schwierig", "Mäßig einfach", "Neutral", "Ziemlich einfach", "Sehr einfach", "Äußerst einfach"]
    },
    '14': {
      question: "Wie zuversichtlich fühlen Sie sich, das Gesehene in eine Klassenaktivität zu integrieren?",
      shortName: "Selbstwirksamkeit",
      questionType: "radio-ordered",
      options: ["Überhaupt nicht zuversichtlich", "Wenig zuversichtlich", "Mäßig zuversichtlich", "Neutral", "Sehr zuversichtlich", "Äußerst zuversichtlich"]
    },
    '15': {
      question: "Inwieweit beabsichtigen Sie, das Gesehene zu nutzen, um eine Klassenaktivität durchzuführen?",
      shortName: "Verhaltensabsicht",
      questionType: "radio-ordered",
      options: ["Überhaupt nicht beabsichtigt", "Geringe Absicht", "Mäßige Absicht", "Neutral", "Starke Absicht", "Sehr starke Absicht", "Absolute Absicht"]
    },
    '16': {
      question: "Wie hoch schätzen Sie Ihren Bedarf an notwendiger Ausrüstung, um die Schulungsressourcen effektiv in Ihre Praxis zu integrieren?",
      shortName: "Materielle Ressourcen",
      questionType: "radio-ordered",
      options: ["Sehr hoch", "Hoch", "Ziemlich hoch", "Mittel", "Ziemlich niedrig", "Niedrig", "Sehr niedrig"]
    },
    '17': {
      question: "Wie hoch schätzen Sie Ihren Bedarf an verfügbarer Zeit, um die Schulungsressourcen in Ihre Praxis zu integrieren?",
      shortName: "Zeitressourcen",
      questionType: "radio-ordered",
      options: ["Sehr hoch", "Hoch", "Ziemlich hoch", "Mittel", "Ziemlich niedrig", "Niedrig", "Sehr niedrig"]
    },
    '18': {
      question: "Wie hoch schätzen Sie Ihren Bedarf an Unterstützung durch Ihre Vorgesetzten, um die Schulungsressourcen in Ihre Praxis zu integrieren?",
      shortName: "Hierarchische Unterstützung",
      questionType: "radio-ordered",
      options: ["Sehr hoch", "Hoch", "Ziemlich hoch", "Mittel", "Ziemlich niedrig", "Niedrig", "Sehr niedrig"]
    },
    '19': {
      question: "Wie hoch schätzen Sie Ihren Bedarf an Unterstützung durch Ihre Kollegen, um die Schulungsressourcen in Ihre Praxis zu integrieren?",
      shortName: "Kollegiale Unterstützung",
      questionType: "radio-ordered",
      options: ["Sehr hoch", "Hoch", "Ziemlich hoch", "Mittel", "Ziemlich niedrig", "Niedrig", "Sehr niedrig"]
    },
    '20': {
      question: "Wie hoch schätzen Sie Ihren Bedarf an technischer Unterstützung, um die Schulungsressourcen in Ihre Praxis zu integrieren?",
      shortName: "Technische Unterstützung",
      questionType: "radio-ordered",
      options: ["Sehr hoch", "Hoch", "Ziemlich hoch", "Mittel", "Ziemlich niedrig", "Niedrig", "Sehr niedrig"]
    },
    '21': {
      question: "Wie hoch schätzen Sie Ihren Bedarf an Mentoring (z.B. durch einen erfahreneren Kollegen), um die Schulungsressourcen in Ihre Praxis zu integrieren?",
      shortName: "Mentoring",
      questionType: "radio-ordered",
      options: ["Sehr hoch", "Hoch", "Ziemlich hoch", "Mittel", "Ziemlich niedrig", "Niedrig", "Sehr niedrig"]
    },
    '22': {
      question: "Wie kompatibel ist das eingeführte Material mit Ihren bisherigen Lehrpraktiken?",
      shortName: "Kompatibilität",
      questionType: "radio-ordered",
      options: ["Überhaupt nicht kompatibel", "Wenig kompatibel", "Ziemlich kompatibel", "Neutral", "Mäßig kompatibel", "Sehr kompatibel", "Äußerst kompatibel"]
    },
    '23': {
      question: "Wie finden Sie den Fortschritt Ihrer Schüler mit den neuen Materialien?",
      shortName: "Schülerfortschritt",
      questionType: "radio-ordered",
      options: ["Deutlich abgenommen", "Leicht abgenommen", "Gleich geblieben", "Leicht zugenommen", "Mäßig zugenommen", "Deutlich zugenommen"]
    },
    '24': {
      question: "Wie würden Sie Ihr Selbstwirksamkeitserleben bei der Nutzung der neuen Ressourcen bewerten?",
      shortName: "Selbstwirksamkeit",
      questionType: "radio-ordered",
      options: ["Überhaupt nicht zuversichtlich", "Wenig zuversichtlich", "Ziemlich zuversichtlich", "Neutral", "Mäßig zuversichtlich", "Sehr zuversichtlich", "Äußerst zuversichtlich"]
    },
    '25': {
      question: "Wie geistig anspruchsvoll finden Sie die Umsetzung der neuen Ressourcen?",
      shortName: "Kognitive Belastung",
      questionType: "radio-ordered",
      options: ["Extrem anspruchsvoll", "Sehr anspruchsvoll", "Ziemlich anspruchsvoll", "Mäßig anspruchsvoll", "Leicht anspruchsvoll", "Überhaupt nicht anspruchsvoll"]
    },
    '26': {
      question: "Inwieweit erhöhen die neuen Ressourcen Ihre Arbeitsbelastung?",
      shortName: "Auswirkung auf die Arbeitsbelastung",
      questionType: "radio-ordered",
      options: ["Massiv erhöht", "Deutlich erhöht", "Ziemlich erhöht", "Neutral", "Mäßig erhöht", "Leicht erhöht", "Überhaupt nicht erhöht"]
    },
    '27': {
      question: "Wie lange hat es gedauert, bis Sie die neuen Materialien nach der Schulung umgesetzt haben?",
      shortName: "Umsetzungszeit",
      questionType: "radio-ordered",
      options: ["Noch nicht umgesetzt", "Mehr als 3 Monate", "Innerhalb von 3 Monaten", "Innerhalb eines Monats", "Innerhalb einer Woche", "Sofort"]
    },
    '28': {
      question: "Welche Altersgruppe gehören Sie an?",
      shortName: "Alter",
      questionType: "radio-unordered",
      options: ["Unter 10 Jahren", "10-15 Jahre", "16-20 Jahre", "21-25 Jahre", "26-30 Jahre", "31 Jahre und älter"]
    },
    '29': {
      question: "Was ist Ihr Hauptfachgebiet oder Interessengebiet?",
      shortName: "Spezialisierung",
      questionType: "radio-unordered",
      options: ["Naturwissenschaften", "Künste", "Technologie", "Mathematik", "Geisteswissenschaften", "Andere"]
    },
    '30': {
      question: "Auf welchem Bildungsniveau befinden Sie sich derzeit?",
      shortName: "Bildungsniveau",
      questionType: "radio-unordered",
      options: ["Grundschule", "Sekundarschule", "Bachelor (oder gleichwertig)", "Master/Doktorat (oder gleichwertig)"]
    },
    '31': {
      question: "Wie oft nutzen Sie digitale Werkzeuge zum Lernen oder zur Freizeitgestaltung?",
      shortName: "Nutzung digitaler Werkzeuge",
      questionType: "radio-unordered",
      options: ["Nie", "Selten", "Manchmal", "Oft", "Immer"]
    },
    '32': {
      question: "Glauben Sie, dass bestimmte Geschlechter eher für die Informatik geeignet sind?",
      shortName: "Geschlechterstereotypen",
      questionType: "radio-unordered",
      options: ["Ja, Männer", "Ja, Frauen", "Nein, alle Geschlechter sind gleichermaßen geeignet", "Ich habe keine Meinung zu diesem Thema"]
    }
  }
};

export { questionBank };