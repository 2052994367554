import React, { useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { Box, Typography, IconButton, TextField } from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import RepeatIcon from '@mui/icons-material/Repeat';
import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from 'axios';
import { BACKEND_URL } from "../config";
import { useAuthUser } from '../contexts/AuthUserContext';
import { LogType } from '../utils/enums';
import { useMessageService } from '../services/MessageService';
import { localizeAssessmentType } from '../utils/ObjectsUtils';

const CustomTimeline = ({ logs, setLogs, currentMonitoringId }) => {
  const { currentUser } = useAuthUser();
  const { getMessage } = useMessageService();
  const [editingIndex, setEditingIndex] = useState(null);
  const [tempDescription, setTempDescription] = useState("");

  // Only reverse logs once for display
  const displayLogs = [...logs].reverse();

  const handleDelete = async (displayIndex) => {
    // Convert display index to actual index in the original logs array
    const actualIndex = logs.length - 1 - displayIndex;
    const newLogs = [...logs];
    newLogs.splice(actualIndex, 1);
    setLogs(newLogs);

    const serverLogs = {
      userId: currentUser._id, 
      monitoringId: currentMonitoringId,
      logs: newLogs,
    };

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${BACKEND_URL}/logs/${currentMonitoringId}`,
        serverLogs,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if(response.status === 200) {
        console.log('Log deleted successfully:');
      } else {
        console.log('An error occurred while deleting the log:', response);
      }
    } catch (error) {
      console.log('An error occurred while deleting the log:', error);
    }
  };

  const handleStartEdit = (index, description) => {
    setEditingIndex(index);
    setTempDescription(description);
  };

  const handleSave = async (displayIndex) => {
    // Convert display index to actual index in the original logs array
    const actualIndex = logs.length - 1 - displayIndex;
    const newLogs = [...logs];
    newLogs[actualIndex] = {
      ...newLogs[actualIndex],
      description: tempDescription,
      lastModificationDate: new Date().toISOString()
    };

    setLogs(newLogs);
    setEditingIndex(null);

    const serverLogs = {
      userId: currentUser._id,
      monitoringId: currentMonitoringId,
      logs: newLogs,
    };

    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${BACKEND_URL}/logs/${currentMonitoringId}`,
        serverLogs,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      console.log('Log updated successfully');
    } catch (error) {
      console.error('Error updating log:', error);
    }
  };

  if (logs.length === 0) {
    return <Typography mt="50px" variant="h6">{getMessage("label_no_entries")}</Typography>;
  }

  return (
    <Box p={2} m={2}>
      <Typography mb="5vh" variant="h3" fontWeight="bold">
        {getMessage("label_my_training_activity")}
      </Typography>

      <Box sx={{overflowY: 'auto', mt: '15px', height: "70vh", minWidth: "40vw"}}>
        <Timeline>
          {displayLogs.map((log, displayIndex) => {
            const isEditing = editingIndex === displayIndex;

            return (
              <TimelineItem 
                key={displayIndex}
                sx={{
                  '&::before': {
                    display: 'none'
                  }
                }}
              >
                <TimelineOppositeContent sx={{ display: 'flex', alignItems: 'center', px: '5%' }} variant="body2" color="text.secondary">
                  <Box display="flex" flexDirection="row" alignItems="center" flexGrow={1} sx={{ bgcolor: 'lightgray', borderRadius: '16px', p: 2 }}>
                    <IconButton onClick={() => handleDelete(displayIndex)}>
                      <Delete sx={{ color: 'red' }} />
                    </IconButton>

                    <Typography variant="h4" fontWeight="bold" sx={{ ml: 2, whiteSpace: 'nowrap' }}>
                      Session {log.day}
                    </Typography>
                  </Box>
                </TimelineOppositeContent>

                <TimelineSeparator>
                  <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
                  <TimelineDot style={{ backgroundColor: 'rgb(236, 141, 53)' }}>
                    {log.logType === LogType.OBSERVATION ? <VisibilityIcon /> : <RepeatIcon />}
                  </TimelineDot>
                  <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
                </TimelineSeparator>

                <TimelineContent 
                  sx={{
                    py: '2%', 
                    px: '7%', 
                    bgcolor: 'lightgray', 
                    borderRadius: '16px', 
                    margin: '10px',
                    position: 'relative',
                    '& .edit-button': {
                      opacity: 0,
                      transition: 'opacity 0.2s'
                    },
                    '&:hover .edit-button': {
                      opacity: 1
                    }
                  }}
                >
                  <IconButton 
                    className="edit-button"
                    onClick={() => isEditing ? handleSave(displayIndex) : handleStartEdit(displayIndex, log.description)}
                    aria-label={isEditing ? `Save log ${log.day}` : `Edit log ${log.day}`}
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      bgcolor: 'white',
                      '&:hover': {
                        bgcolor: 'rgba(255, 255, 255, 0.9)',
                      }
                    }}
                  >
                    {isEditing ? <SaveIcon color="primary" /> : <EditIcon />}
                  </IconButton>

                  <Typography variant="h4" component="div" fontWeight="bold" sx={{ mb: 1 }}>
                    {localizeAssessmentType(log.assessment, getMessage)}
                  </Typography>

                  {isEditing ? (
                    <TextField
                      fullWidth
                      multiline
                      rows={3}
                      value={tempDescription}
                      onChange={(e) => setTempDescription(e.target.value)}
                      autoFocus
                      variant="outlined"
                    />
                  ) : (
                    <Typography 
                      variant="h5" 
                      onClick={() => handleStartEdit(displayIndex, log.description)}
                      sx={{ cursor: 'pointer' }}
                    >
                      {log.description}
                    </Typography>
                  )}
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </Box>
    </Box>
  );
};

export default CustomTimeline;